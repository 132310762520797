<script>
  import { link } from "svelte-routing";

  // core components
  import IndexNavbar from "components/Navbars/IndexNavbar.svelte";
  import Footer from "components/Footers/Footer.svelte";

  const client1 = "/assets/img/clients/lmcapital.png";
  const client2 = "/assets/img/clients/kholanhbk.png";
  const client3 = "/assets/img/clients/dgsoft.png";
  //const client3 = "https://cypeace.net/wp-content/uploads/2023/07/cypeace.png";
  const client4 = "/assets/img/clients/pixelcent.png";
  const client5 = "/assets/img/clients/minhkhangexpress.png";
  const client0 = "/assets/img/clients/vinova.png";
  
  export let location;
</script>

<div class="scroll-smooth focus:scroll-auto">
  <IndexNavbar />
  <main>
    <div
      class="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75"
    >
      <div
        class="absolute top-0 w-full h-full bg-center bg-cover"
        style="
          background-image: url(https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80);
        "
      >
        <span
          id="blackOverlay"
          class="w-full h-full absolute opacity-75 bg-black"
        ></span>
      </div>
      <div class="container relative mx-auto">
        <div class="items-center flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
            <div class="pr-12">
              <h2 class="text-white font-semibold text-5xl">
                SOFTWARE DEVELOPMENT SERVICES
              </h2>
              <p class="mt-4 text-lg text-slate-200">
                Simplify your problems
                to turn your innovative ideas to reality
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="top-auto -bottom-1-px left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
        style="transform: translateZ(0);"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            class="text-slate-200 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>

    <section id="services" class="pb-20 bg-slate-200 -mt-24 mb-1-px">
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap">
          <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
            <div
              class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
            >
              <div class="px-4 py-5 flex-auto">
                <div
                  class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-orange-400"
                >
                  <i class="fas fa-cogs"></i>
                </div>
                <h6 class="text-xl font-semibold">Custom Software Development</h6>
                <p class="mt-2 mb-4 text-slate-500">
                  Developing custom software that caters to your unique needs, 
                  including both front-end and core back-end technologies
                </p>
              </div>
            </div>
          </div>

          <div class="w-full md:w-4/12 px-4 text-center">
            <div
              class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
            >
              <div class="px-4 py-5 flex-auto">
                <div
                  class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-orange-400"
                >
                  <i class="fas fa-desktop"></i>
                </div>
                <h6 class="text-xl font-semibold">UI / UX Design</h6>
                <p class="mt-2 mb-4 text-slate-500">
                  Designing visually stunning, user-friendly 
                  interfaces that delight your end users
                </p>
              </div>
            </div>
          </div>

          <div class="pt-6 w-full md:w-4/12 px-4 text-center">
            <div
              class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
            >
              <div class="px-4 py-5 flex-auto">
                <div
                  class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-orange-400"
                >
                  <i class="fas fa-lightbulb"></i>
                </div>
                <h6 class="text-xl font-semibold">IT Consulting</h6>
                <p class="mt-2 mb-4 text-slate-500">
                  We don’t just provide consulting services, 
                  we partner with you to create a future-ready 
                  IT roadmap that is tailored to your business needs
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap items-center mt-32">
          <div class="w-full md:w-5/12 px-4 mr-auto ml-auto">
            <div
              class="text-slate-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
            >
              <i class="fas fa-user-friends text-xl"></i>
            </div>
            <h3 class="text-3xl mb-2 font-semibold leading-normal">
              We are your partners in digital transformation.
            </h3>
            <p
              class="text-lg font-light leading-relaxed mt-4 mb-4 text-slate-600"
            >
            We help you innovate, strategize, and achieve your business goals 
            with our custom software development services
            </p>
            <p
              class="text-lg font-light leading-relaxed mt-4 mb-4 text-slate-600"
            >
              Our team has extensive experience in various domains:
            </p>
            <ul class="list-none mt-4">
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span
                      class="text-xs font-semibold inline-block p-2 uppercase rounded-full bg-gray-100 mr-3"
                    >
                      <i class="fas fa-stethoscope text-slate-400"></i>
                    </span>
                  </div>
                  <div>
                    <h4 class="text-slate-500">
                      Healthcare
                    </h4>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span
                      class="text-xs font-semibold inline-block p-2 uppercase rounded-full  bg-gray-100 mr-3"
                    >
                      <i class="fa fa-shield-alt text-slate-400"></i>
                    </span>
                  </div>
                  <div>
                    <h4 class="text-slate-500">
                      Insurance
                    </h4>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span
                      class="text-xs font-semibold inline-block p-2 uppercase rounded-full bg-gray-100 mr-3"
                    >
                      <i class="fa fa-shopping-cart text-slate-400"></i>
                    </span>
                  </div>
                  <div>
                    <h4 class="text-slate-500">ecommerce</h4>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span
                      class="text-xs font-semibold inline-block p-2 uppercase rounded-full bg-gray-100 mr-3"
                    >
                      <i class="fa fa-truck-moving text-slate-400"></i>
                    </span>
                  </div>
                  <div>
                    <h4 class="text-slate-500">Logistic</h4>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div class="w-full md:w-4/12 px-4 mr-auto ml-auto pt-8 md:pt-0">
            <div
              class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-orange-400"
            >
              <img
                alt="..."
                src="https://images.unsplash.com/photo-1523961131990-5ea7c61b2107?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80"
                class="w-full align-middle rounded-t-lg"
              />
              <blockquote class="relative p-8 mb-4 bg-orange-400">
                <svg
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 583 95"
                  class="absolute left-0 w-full block h-95-px -top-94-px"
                >
                  <polygon
                    points="-30,95 585,95 585,65"
                    class="text-orange-400 fill-current"
                  ></polygon>
                </svg>
                <h4 class="text-xl font-bold text-white">
                  Empower your business
                </h4>
                <p class="text-md font-light mt-2 text-white opacity-70">
                  We apply our profound technology insights and 
                  outstanding software engineering capabilities to 
                  enable your enterprise to reach digital transformation maturity
                </p>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="relative py-20">
      <div
        class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
        style="transform: translateZ(0);"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            class="text-white fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>

      <div class="container mx-auto px-4">
        <div class="items-center flex flex-wrap">
          <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
            <img
              alt="..."
              class="max-w-full rounded-lg shadow-lg"
              src="https://images.unsplash.com/photo-1516321318423-f06f85e504b3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
            />
          </div>
          <div class="w-full md:w-5/12 ml-auto mr-auto px-4 pt-8 md:pt-0">
            <div class="md:pr-12">
              <div
                class="text-slate-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-slate-100"
              >
                <i class="fas fa-rocket text-xl"></i>
              </div>
              <h3 class="text-3xl font-semibold">Bring your ideas to life with custom software solutions</h3>
              <p class="mt-4 text-lg leading-relaxed text-slate-500">
                We offer IT strategy consulting, technology roadmaps, 
                and end-to-end development of scalable solutions that 
                fit your project requirements and business needs 
              </p>              
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="clients" class="pt-20 pb-48">
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap justify-center text-center mb-24">
          <div class="w-full lg:w-6/12 px-4">
            <h2 class="text-4xl font-semibold">Our Clients</h2>
            <p class="text-lg leading-relaxed m-4 text-slate-500">
              We has been helping our customers to build solid softwares for their business.
            </p>
          </div>
        </div>
        <div class="flex md:flex-nowrap flex-wrap justify-center items-center">
          <div class="mb-12 px-4">
            <div class="px-6">
              <img
                alt="..."
                src="{client0}"
                class="rounded-none mx-auto max-w-120-px"
              />
            </div>
          </div>
          <div class="mb-12 px-4">
            <div class="px-6">
              <img
                alt="..."
                src="{client1}"
                class="rounded-none mx-auto max-w-120-px"
              />
            </div>
          </div>
          <div class="mb-12 px-4 hidden">
            <div class="px-6">
              <img
                alt="..."
                src="{client2}"
                class="rounded-none mx-auto max-w-120-px"
              />
              
            </div>
          </div>
          <div class="mb-12 px-4">
            <div class="px-6">
              <img
                alt="..."
                src="{client3}"
                class="rounded-none mx-auto max-w-120-px"
              />
              
            </div>
          </div>
          <div class="mb-12 px-4">
            <div class="px-6">
              <img
                alt="..."
                src="{client4}"
                class="rounded-none mx-auto max-w-120-px"
              />
            </div>
          </div>
          <div class="mb-12 px-4">
            <div class="px-6">
              <img
                alt="..."
                src="{client5}"
                class="rounded-none mx-auto max-w-120-px"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="about" class="pb-20 relative block bg-slate-800">
      <div
        class="bottom-auto top-1-px left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
        style="transform: translateZ(0);"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            class="text-slate-800 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>

      <div class="container mx-auto px-4 lg:pt-24 lg:pb-64">
        <div class="flex flex-wrap text-center justify-center">
          <div class="w-full lg:w-6/12 px-4 pt-16 md:pt-0">
            <h2 class="text-4xl font-semibold text-white">
              Tech Stack
            </h2>
            <p class="text-lg leading-relaxed mt-4 mb-4 text-slate-400">
              Our team has expertise in a diverse range of technologies, 
              including but not limited to backend, frontend, and database
            </p>
          </div>
        </div>
        <div class="flex flex-wrap mt-12 justify-center">
          <div class="w-full lg:w-3/12 px-4 text-center">
            <div
              class="text-slate-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
            >
              <i class="fas fa-code text-xl"></i>
            </div>
            <h6 class="text-xl mt-5 font-semibold text-white">
              Programming Languages
            </h6>
            <p class="mt-2 mb-4 text-slate-600 leading-8 opacity-60">
              <span class="break-words rounded-2xl border-1 border-slate-600 bg-slate-50 px-2 py-0.5">Java</span>
              <span class="break-words rounded-2xl border-1 border-slate-600 bg-slate-50 px-2 py-0.5">C#</span>
              <span class="break-words rounded-2xl border-1 border-slate-600 bg-slate-50 px-2 py-0.5">Python</span>
              <span class="break-words rounded-2xl border-1 border-slate-600 bg-slate-50 px-2 py-0.5">PHP</span>
              <span class="break-words rounded-2xl border-1 border-slate-600 bg-slate-50 px-2 py-0.5">Javascript</span>
            </p>
          </div>
          <div class="w-full lg:w-3/12 px-4 text-center pt-8 md:pt-0">
            <div
              class="text-slate-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
            >
              <i class="fas fa-cog text-xl"></i>
            </div>
            <h5 class="text-xl mt-5 font-semibold text-white">
              Frameworks
            </h5>
            <div class="mt-2 mb-4 text-slate-600 leading-8 opacity-60">
              <span class="break-words rounded-2xl border-1 border-slate-600 bg-slate-50 px-2 py-0.5">SpringBoot</span>
              <span class="break-words rounded-2xl border-1 border-slate-600 bg-slate-50 px-2 py-0.5">.NetCore</span>
              <span class="break-words rounded-2xl border-1 border-slate-600 bg-slate-50 px-2 py-0.5">EntityFramework</span>
              <span class="break-words rounded-2xl border-1 border-slate-600 bg-slate-50 px-2 py-0.5">MVC</span>
              <span class="break-words rounded-2xl border-1 border-slate-600 bg-slate-50 px-2 py-0.5">Angular</span>
              <span class="break-words rounded-2xl border-1 border-slate-600 bg-slate-50 px-2 py-0.5">Vue</span>
              <span class="break-words rounded-2xl border-1 border-slate-600 bg-slate-50 px-2 py-0.5">React</span>
            </div>
          </div>
          <div class="w-full lg:w-3/12 px-4 text-center pt-8 md:pt-0">
            <div
              class="text-slate-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
            >
              <i class="fas fa-database text-xl"></i>
            </div>
            <h5 class="text-xl mt-5 font-semibold text-white">
              Databases
            </h5>
            <p class="mt-2 mb-4 text-slate-600 leading-8 opacity-60">
              <span class="break-words rounded-2xl border-1 border-slate-600 bg-slate-50 px-2 py-0.5">Oracle</span>
              <span class="break-words rounded-2xl border-1 border-slate-600 bg-slate-50 px-2 py-0.5">MS SQL</span>
              <span class="break-words rounded-2xl border-1 border-slate-600 bg-slate-50 px-2 py-0.5">MySql</span>
              <span class="break-words rounded-2xl border-1 border-slate-600 bg-slate-50 px-2 py-0.5">DB2</span>
              <span class="break-words rounded-2xl border-1 border-slate-600 bg-slate-50 px-2 py-0.5">PostgreSQL</span>
              <span class="break-words rounded-2xl border-1 border-slate-600 bg-slate-50 px-2 py-0.5">Mongodb</span>
              <span class="break-words rounded-2xl border-1 border-slate-600 bg-slate-50 px-2 py-0.5">DynamobDb</span>
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="relative block py-24 lg:pt-0 bg-slate-800">
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap justify-center lg:-mt-64 -mt-48">
          <div class="w-full lg:w-6/12 px-4">
            <div
              class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-200"
            >
              <div class="flex-auto p-5 lg:p-10 hidden">
                <h4 class="text-2xl font-semibold">
                  Want to work with us?
                </h4>
                <p class="leading-relaxed mt-1 mb-4 text-slate-500">
                  Complete this form and we will get back to you in 24 hours.
                </p>
                <div class="relative w-full mb-3 mt-8">
                  <label
                    class="block uppercase text-slate-600 text-xs font-bold mb-2"
                    for="full-name"
                  >
                    Full Name
                  </label>
                  <input
                    id="full-name"
                    type="text"
                    class="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Full Name"
                  />
                </div>

                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-slate-600 text-xs font-bold mb-2"
                    for="email"
                  >
                    Email
                  </label>
                  <input
                    id="email"
                    type="email"
                    class="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Email"
                  />
                </div>

                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-slate-600 text-xs font-bold mb-2"
                    for="message"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    rows="4"
                    cols="80"
                    class="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                    placeholder="Type a message..."
                  />
                </div>
                <div class="text-center mt-6">
                  <button
                    class="bg-slate-800 text-white active:bg-slate-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <Footer />
</div>